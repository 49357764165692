table {
  /* max-width: 1000px; */
}

.scoreboard {
  font-family: montserrat, sans-serif;
}

.scoreboard .header .player {
  text-align: left !important;
}

.tbodybatter-bowler tr:first-child td {
  padding-bottom: 5px !important;
}

.tbodybatter-bowler tr:first-child td.player {
  padding-left: 3rem !important;
}

.scoreboard .header .player img {
  width: 35px;
}

.player-img {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 3px 3px;
  width: 35px;
  height: 32px;
  padding: 1px;
  border-radius: 3px;
  object-fit: cover;
  object-position: center bottom;
  overflow: hidden;
}

.fontSmall {
  font-weight: lighter !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bar {
  width: 305px;
  float: right;
  margin-right: 30px;
  margin-bottom: 0px !important;
  display: flex;
  background-color: transparent;
  height: 27px;
}

.barMobi {
  width: 305px;
  float: right;
  margin-bottom: 0px !important;
  display: flex;
  background-color: transparent;
}

.ProgressLeft {
  width: 110px;
  text-align: center;
  color: #fff;
  margin-left: 5px;
  font-size: 12px;
  height: 12px;
}

.ProgressRight {
  width: 110px;
  text-align: center;
  color: #fff;
  margin-right: 5px;
  font-size: 12px;
  height: 12px;
}

.ProgressCenter {
  width: 85%;
  height: 6px;
  position: relative;
  top: 7px;
}

.tblmargin {
  margin-bottom: 4px;
}

.dynamic-score {
  position: relative;
  width: 100%;
}

.progress-bars-1 {
  line-height: 21px;

  .progress-title {
    .progress-amount {
      color: var(--score-team-title);
      font-size: 10px;
      font-weight: 500;
    }
  }

  .progress-content {
    width: 96%;
  }

  .progress {
    width: 98%;
    height: 6px;
    background: transparent;
    border-radius: 5px;
    border: 0.5px solid var(--score-team-title);
    /* margin-bottom: 5px; */
    display: flex;

    .determinate {
      border-radius: 27.22px;
    }

    /* .progress-left {
      display: flex;
      width: 60%;
      background: var(--determinate-bg);
    }

    .progress-right {
      display: flex;
      width: 40%; 
      background-color: var(--progress-bg);
    } */
  }
}

.backgound-banner {
  background-color: transparent;
}

.umpire-image1 {
  position: absolute;
  top: 13%;
  right: -100px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 60px;
  animation: slideFromRight1 1s forwards;
}

.foreground-text1 {
  font-size: 40px;
  font-family: monospace;
  font-weight: bolder;
  position: absolute;
  left: 0;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  animation: drawNumber1 1s forwards;
  overflow: hidden;
  width: 0;
}

.valign-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.valign-wrapper-score-left {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  text-align: right;
}

.valign-wrapper-score-right {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  text-align: left;
}

.scoreboard-2 {
  padding-left: 20px;
  padding-right: 20px;
  background-color: transparent;
  padding-bottom: 15px;
  padding-top: 5px;
}

.scoreboard-2 .score-header {}

.scoreboard-2 .score-header .top-header {
  background-color: #000;
  color: #fff;
  border-radius: 30px;
  height: auto;
  min-height: 60px;
}

.scoreboard-2 .score-header .top-header-1 {
  min-height: 60px;
}

.top-header-exception {
  text-align: center;
  min-height: 40px !important;
}

.scoreboard-2 .score-header .left-team,
.scoreboard-2 .score-header .right-team {
  width: 50%;
  position: relative;
}

.scoreboard-2 .score-header .separator {
  width: 190px;
  position: relative;
}

.scoreboard-2 .score-header .more-tab {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 9;
}

.scoreboard-2 .score-header .more-tab .more-arrow {
  position: absolute;
  width: 100px;
  bottom: -15px;
}

.scoreboard-2 .score-header .more-tab .down-arrow {
  border: none;
  left: 2px;
  position: relative;
  top: -16px;
  width: 100px;
  height: 16px;
  line-height: 0.3;
  border-radius: 50%;
  display: block;
  margin: auto;
  color: #000;
}

.scoreboard-2 .score-header .more-tab .down-arrow img {
  max-width: 22px;
}

.scoreboard-2 .score-header .more-tab .down-arrow svg {
  max-width: 100px;
  height: 15px;
}

.scoreboard-2 .score-header .left-team .team-name,
.scoreboard-2 .score-header .right-team .team-name {
  /* color: #fff; */
  font-size: 24px;
  font-weight: 600;
  line-height: 2.3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 3px;
  position: relative;
  align-items: center;
  text-transform: uppercase;
}

.scoreboard-2 .score-header .left-team .team-name {
  padding-left: 55px;
}

.scoreboard-2 .score-header .right-team .team-name {
  padding-right: 55px;
}

.scoreboard-2 .score-header .left-team .team-name .team-img,
.scoreboard-2 .score-header .right-team .team-name .team-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  box-shadow: inset 1px 2px 16px #7a7a7a;
  background: #fff;
  line-height: 2;
  display: inline-block;
  position: absolute;
  font-size: x-small;
}

.scoreboard-2 .score-header .left-team .team-name .team-img {
  left: 2px;
}

.scoreboard-2 .score-header .right-team .team-name .team-img {
  right: 2px;
}

.team-img img {
  border-radius: 50%;
}

.scoreboard-2 .score-header .left-team .team-name img,
.scoreboard-2 .score-header .right-team .team-name img,
.scoreboard-2 .score-header .separator .team-name img {
  width: 50px;
}

.scoreboard-2 .score-header .left-team .team-name .team-title,
.scoreboard-2 .score-header .right-team .team-name .team-title,
.scoreboard-2 .score-header .separator .team-name .team-title {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scoreboard-2 .score-header .left-team .team-name .left-score,
.scoreboard-2 .score-header .right-team .team-name .right-score {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  text-shadow: 0 6px 10px rgb(0 0 0/25%);
}

.scoreboard-2 .score-header .left-team .team-name .team-title {
  text-align: center;
  /* color: #fff; */
}

.scoreboard-2 .score-header .right-team .team-name .team-title {
  text-align: center;
  /* color: #fff; */
}

.scoreboard-2 .score-header .detail-score .left-score,
.scoreboard-2 .score-header .detail-score .right-score {
  position: relative;
  top: -12px;
  right: 12px;
  width: 100px;
  /* background-color: #002d55; */
  -webkit-box-shadow: #343a40 0 3px 9px, rgb(143 143 143/43%) 0 3px 3px;
  box-shadow: #343a40 0 3px 9px, rgb(143 143 143/43%) 0 3px 3px;
}

.scoreboard-2 .score-header .left-team .left-score .team-score,
.scoreboard-2 .score-header .right-team .right-score .team-score {
  /* color: #fff; */
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  z-index: 1;
  position: relative;
}

.scoreboard-2 .score-header .left-score .overs,
.scoreboard-2 .score-header .right-score .overs {
  font-size: 10px;
  font-weight: 600;
  /* color: #edf9ff; */
  line-height: 1;
  bottom: 1px;
  position: relative;
  text-align: left;
  z-index: 1;
  letter-spacing: 0.5px;
}

.scoreboard-2 .score-header .left-score .overs span,
.scoreboard-2 .score-header .right-score .overs span {
  font-weight: 500;
}

.scoreboard-2 .score-header .detail-score .small-score-details {
  /* background-color: #000; */
  border-radius: 20px;
  position: relative;
  top: 3px;
  width: 100%;
  padding: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: auto;
  min-height: 35px;
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-right .run-rates {
  justify-content: end;
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-left,
.scoreboard-2 .score-header .detail-score .small-score-details .details-center,
.scoreboard-2 .score-header .detail-score .small-score-details .details-right {
  width: 33.33%;
  z-index: 1;
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-left .last-over {
  display: inline-flex;
  margin: 1px 0;
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-left .current-over {
  display: inline-flex;
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-left .last-over .balls {
  margin: 2px 0 1px 2px;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-left .last-over .balls .ball {
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  min-width: 16px;
  height: 16px;
  line-height: 27px;
  text-align: center;
  padding: 1px 4px;
  font-size: 10px;
  font-weight: 600;
  margin: 1px 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 5px 3px rgb(0 0 0/28%);
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-center .runs-need {
  font-size: 10px;
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-right .run-rates {
  bottom: 0;
}

.scoreboard-2 .score-header .detail-score .small-score-details .details-right .run-rates .run-rate {
  /* color: #fff; */
}

.scoreboard-2 .score-header .detail-score .small-score-details .more-tab {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 9;
}

.scoreboard-2 .score-header .detail-score .small-score-details .more-tab .more-arrow {
  position: absolute;
  width: 100px;
  bottom: -35px;
}

.scoreboard-2 .score-header .detail-score .small-score-details .more-tab .more-arrow .down-arrow {
  border: none;
  left: 2px;
  position: relative;
  top: 17px;
  text-align: center;
  /* color: #000; */
}

.scoreboard-2 .score-header .detail-score .small-score-details .more-tab .more-arrow .down-arrow:before,
.scoreboard-2 .score-header .detail-score .small-score-details .more-tab .more-arrow .down-arrow:after {
  display: none;
}

.scoreboard-2 .score-header .detail-score .score-details {
  /* background-color: #000; */
  border-radius: 20px;
  top: -40px;
  width: 100%;
  padding: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  position: relative;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);
}

.scoreboard-2 .score-header .detail-score .score-details.down-content {
  top: 13px;
  visibility: visible;
  opacity: 1;
}

.scoreboard-2 .score-header .detail-score .score-details .runs-need {
  width: 100%;
  /* position: absolute; */
  bottom: -2px;
}

.scoreboard-2 .score-header .detail-score .score-details .runs-need .title {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  bottom: 0;
  font-size: 10px;
  font-weight: 500;
  /* color: #fff; */
  display: block;
}

.title-exception {
  font-size: 9px !important;
  bottom: 0;
  /* color: #fff; */
  font-weight: 400px;
}

.progress-exception {
  width: 100%;
  float: left;
  position: relative;
  margin-top: -9px;
}

.progressInnerDiv {
  position: relative;
  top: -4px;
}

.ProgressCenterInnerDiv1 {
  width: 50%;
  background-color: #008000;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.ProgressCenterInnerDiv2 {
  width: 50%;
  background-color: #008000;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.progressPosition {
  position: relative;
  top: -4px;
}

.scoreboard-2 .score-header .detail-score .score-details .details-left,
.scoreboard-2 .score-header .detail-score .score-details .details-right {
  width: 50%;
  z-index: 1;
  padding: 10px 0;
}

.scoreboard-2 .score-header .detail-score .score-details .details-center {
  top: 2px;
  position: relative;
  width: 240px;
}

.scoreboard-2 .score-header .detail-score .score-details .runrate-center {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.scoreboard-2 .score-header .detail-score .score-details .details-left {
  padding-left: 3px;
}

.scoreboard-2 .score-header .center-content {
  text-align: center;
  background: #d3d3d3;
  box-shadow: inset 5px 5px 15px #7a7a7a;
  width: 190px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  border-radius: 60px;
  position: relative;
  top: -1px;
}

.scoreboard-2 .score-header .center-content .vs-image img {
  max-width: 25px;
}

.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details {
  background: #d3d3d3;
  box-shadow: inset 5px 5px 15px #7a7a7a;
  border-radius: 15px;
  padding: 5px 0;
  min-height: 51px;
}

.scoreboard-2 .score-header .current-over,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 1px 0;
}

.scoreboard-2 .score-header .current-over .balls,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls {
  margin: 1px 0 0 4px;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.scoreboard-2 .score-header .ballsviewwithRates .balls,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls {
  margin: 0 0 0 0;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.scoreboard-2 .score-header .current-over .balls .ball,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball {
  border-radius: 30px;
  background-color: #fff;
  color: #000;
  min-width: 16px;
  height: 16px;
  line-height: 27px;
  text-align: center;
  padding: 1px 4px;
  font-size: 7px;
  font-weight: 600;
  margin: 1px 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0 5px 3px rgb(0 0 0/28%);
}

.scoreboard-2 .balls .ball.INFIELDBOUNDARY_4,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.INFIELDBOUNDARY_4 {
  background-color: #2e4 !important;
}

.scoreboard-2 .score-header .current-over .balls .ball.INFIELDBOUNDARY_0,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.INFIELDBOUNDARY_0 {
  background-color: #999;
}

.scoreboard-2 .score-header .current-over .balls .ball.INFIELDBOUNDARY_2,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.INFIELDBOUNDARY_2 {
  background-color: #62aff2eb;
}

.scoreboard-2 .score-header .current-over .balls .ball.INFIELDBOUNDARY_1,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.INFIELDBOUNDARY_1 {
  background-color: #62aff2eb;
}

.scoreboard-2 .score-header .current-over .balls .ball.WICKET,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.WICKET {
  background-color: #c9362b;
}

.scoreboard-2 .score-header .current-over .balls .ball.INFIELDBOUNDARY_6,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.INFIELDBOUNDARY_6 {
  background-color: #f5cb4b;
}

.scoreboard-2 .score-header .current-over .balls .ball.NOBALL,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.NOBALL {
  background-color: #ff5733;
}

.scoreboard-2 .score-header .current-over .balls .ball.ByBALL,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.ByBALL {
  background-color: #cf7977;
}

.scoreboard-2 .score-header .current-over .balls .ball.WIDE,
.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball.WIDE {
  background-color: #1de4d5;
}

.scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball {
  /* color: #000; */
}

.scoreboard-2 .score-header .detail-score .run-rates {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  position: relative;
  bottom: -3px;
  height: 20px;
  border-radius: 5px;
  width: auto;
  margin: auto;
}

.scoreboard-2 .score-header .detail-score .score-details .runrate-center .add-icon {
  position: absolute;
  right: 12px;
  bottom: 7px;
  z-index: 9;
}

.scoreboard-2 .score-header .detail-score .score-details .runrate-center .add-icon a img {
  width: 35px;
  height: 35px;
}

.scoreboard-2 .score-header .detail-score .run-rates .run-rate {
  color: #474747;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  margin: 0 5px;
  line-height: 1.4;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.scoreboard-2 .score-header .detail-score .run-rates .run-rate div {
  margin-left: 3px;
  font-weight: 600;
}

.scoreboard-2 .score-header .detail-score .score-details .details-right {
  padding-right: 3px;
}

.scoreboard-2 .score-header .detail-score .score-details table {
  width: 100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}

.scoreboard-2 .score-header .detail-score .score-details table tr.header td {
  /* color: #ddd; */
}

.scoreboard-2 .score-header .detail-score .score-details table tr td {
  /* color: #fff; */
  padding: 2px 5px;
  border-radius: 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.5px;
}

.scoreboard-2 .score-header .detail-score .score-details .batsman-name {
  /* color: #fff; */
  font-size: 10px;
  font-weight: 500;
  line-height: 17px;
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.zoominout {
  background-image: url(/public/assets/images/run.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.7;
  z-index: 1;
  -webkit-animation: txt 3s ease-out infinite;
  animation: txt 3s ease-out infinite;
  color: #000;
  background-color: #fff;
  margin-left: 3px;
  border-radius: 25px;
}

@-webkit-keyframes txt {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes txt {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.seprate {
  color: #fff;
  line-height: 23px;
  padding-left: 2px;
  padding-right: 3px;
}

.runs-need {
  /* color: #fff; */
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 0 5px;
  border-radius: 0;
  font-size: 8px;
  font-weight: 400;
  line-height: 20px;
}

.status-text {
  color: #fff;
}

.zoominout-text {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  -webkit-animation: txt 3s ease-out infinite;
  animation: txt 3s ease-out infinite;
}

.mobile-score-details-view {
  display: none;
}

@media (max-width: 359px) {
  .zoominout-text {
    font-size: 8px !important;
  }

  .scoreboard-2 .score-header .detail-score .score-details .details-center .over-details {
    padding: 5px 10px !important;
  }

  .scoreboard-2 .score-header .detail-score .score-details {
    min-height: 75px;
  }
}

@media (max-width: 575px) {
  .progress-bars-1 {
    .progress {
      width: 94% !important;
      height: 5px !important;
    }
    .progress-content {
      width: 92% !important;
    }
  }

  .zoominout-text {
    font-size: 8px !important;
    line-height: 1rem !important;
  }

  .umpire-image1 {
    top: 8%;
    width: 40px;
    animation: slideFromRight1 1s forwards;
  }

  .foreground-text1 {
    font-size: 10px;
  }

  .scoreboard-2 {
    padding: 8px 2px 10px;
  }

  .scoreboard-2 .score-header .separator {
    width: 65px;
  }

  .scoreboard-2 .score-header .center-content {
    width: 55px;
    height: 30px;
  }

  .scoreboard-2 .score-header .center-content .vs-image img {
    max-width: 20px;
  }

  .scoreboard-2 .score-header .top-header {
    min-height: 40px;
    border-radius: 20px;
  }

  .scoreboard-2 .score-header .top-header-1 {
    min-height: 40px;
  }

  .scoreboard-2 .score-header .left-team .team-name .team-img,
  .scoreboard-2 .score-header .right-team .team-name .team-img {
    width: 36px;
    height: 36px;
  }

  .scoreboard-2 .score-header .left-team .team-name img,
  .scoreboard-2 .score-header .right-team .team-name img,
  .scoreboard-2 .score-header .separator .team-name img {
    width: 38px;
    height: 38px;
    top: 6px;
    left: 7px;
  }

  .scoreboard-2 .score-header .left-team .team-name .team-title,
  .scoreboard-2 .score-header .right-team .team-name .team-title,
  .scoreboard-2 .score-header .separator .team-name .team-title {
    max-width: 37px;
    text-overflow: inherit;
    letter-spacing: 0;
  }

  .scoreboard-2 .score-header .left-team .team-name,
  .scoreboard-2 .score-header .right-team .team-name {
    font-size: 14px;
    line-height: 1.5;
  }

  .scoreboard-2 .score-header .left-team .left-score .team-score,
  .scoreboard-2 .score-header .right-team .right-score .team-score {
    font-size: 14px;
    line-height: 1.1;
  }

  .scoreboard-2 .score-header .left-team .team-name {
    padding-left: 40px;
  }

  .scoreboard-2 .score-header .right-team .team-name {
    padding-right: 40px;
  }

  .scoreboard-2 .score-header .left-score .overs,
  .scoreboard-2 .score-header .right-score .overs {
    font-size: 9px;
  }

  .scoreboard-2 .score-header .detail-score .score-details {
    display: flow-root;
  }

  .scoreboard-2 .score-header .detail-score .score-details .details-left,
  .scoreboard-2 .score-header .detail-score .score-details .details-right {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .scoreboard-2 .score-header .detail-score .score-details .details-center {
    width: 100%;
  }

  .dekstop-score-details-view {
    display: none;
  }

  .mobile-score-details-view {
    display: block;
  }

  .scoreboard-2 .score-header .detail-score .score-details table tr td {
    /* font-size: 12px; */
  }

  .scoreboard .header .player img {
    width: 25px;
  }

  .scoreboard-2 .score-header .detail-score .score-details .details-center .over-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    padding: 3px;
    background-image: none;
    box-shadow: none;
    background-color: transparent;
  }

  .scoreboard-2 .score-header .detail-score .small-score-details {
    display: none;
  }

  .scoreboard-2 .score-header .current-over,
  .scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over {
    z-index: 1;
  }

  .scoreboard-2 .score-header .current-over .balls .ball,
  .scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball {
    min-width: 17px;
    height: 17px;
    margin: 0 2px;
    font-size: 10px;
    font-weight: 500;
  }

  .scoreboard-2 .score-header .detail-score .run-rates .run-rate {
    font-size: 10px;
    margin: 0 3px;
    /* color: #fff; */
  }

  .scoreboard-2 .score-header .detail-score .left-score .team-score,
  .scoreboard-2 .score-header .detail-score .right-score .team-score {
    padding: 5px 0 2px 0;
    font-size: 10px;
    font-weight: 500;
  }

  /* .scoreboard-2 .score-header .detail-score .score-details {
    min-height: 50px;
    justify-content: center;
    top: 3px !important;
  } */

  .ScorePridiction {
    position: absolute;
    top: 25px;
  }

  .scoreboard-2 .more-tab-mobile {
    position: absolute;
    bottom: -6px;
  }

  .scoreboard-2 .more-tab-mobile .more-arrow {
    width: 100px;
    height: 28px;
    border-radius: 0;
    margin: auto;
  }

  .exceptionScoreboard-2 {
    display: none;
  }

  .scoreboard-2 .more-tab-mobile .more-arrow .down-arrow {
    left: 3px;
    position: relative;
    top: 15px;
    margin: auto;
    color: #000;
  }

  .scoreboard-2 .more-tab-mobile .more-arrow .down-arrow img {
    max-width: 22px;
  }

  .scoreboard-2 .score-header .detail-score .run-rates {
    height: 22px;
  }

  .scoreboard-2 .score-header .detail-score .score-details .runrate-center .icon-web {
    display: none;
  }

  .scoreboard-2 .score-header .detail-score .score-details .runrate-center .icon-web a img {
    display: none;
  }

  .scoreboard-2 .score-header .detail-score .score-details .arrow {
    position: absolute;
    background-color: #000;
    bottom: -20px;
    width: 28px;
    height: 25px;
    border-radius: 50%;
  }

  .scoreboard-2 .score-header .detail-score .score-details .arrow .add-icon {
    right: 0;
    bottom: -2px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
    width: 22px;
    height: 22px;
    margin: auto;
  }

  .scoreboard-2 .score-header .detail-score .score-details .arrow .add-icon a img {
    width: 12px;
    height: 12px;
    position: relative;
    top: -4px;
    left: 3px;
  }

  .scoreboard-2 .mobile-score-details {
    margin-top: 15px;
    margin-bottom: 5px;
    /* background-color: #000; */
    border-radius: 10px;
    padding: 5px;
    padding-top: 10px;
    position: relative;
    opacity: 0;
    top: -110px;
  }

  .scoreboard-2 .mobile-score-details.down-content {
    top: 0;
    visibility: visible;
    opacity: 1;
  }

  .scoreboard-2 .small-score-details.down-content {
    top: 10px;
    visibility: visible;
    opacity: 1;
  }

  .scoreboard-2 .mobile-score-details .more-tab-mobile {
    top: -25px;
    position: absolute;
    display: block;
    width: 98%;
    height: 30px;
  }

  .scoreboard-2 .mobile-score-details .more-tab-mobile .more-arrow {}

  .scoreboard-2 .mobile-score-details .more-tab-mobile .more-arrow .down-arrow {
    top: 7px;
  }

  .scoreboard-2 .mobile-score-details table {
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .scoreboard-2 .mobile-score-details table tr.header td {
    /* color: #ddd; */
    font-weight: 600;
  }

  .scoreboard-2 .mobile-score-details table tr td {
    /* color: #fff; */
    padding: 0 5px;
    border-radius: 0;
    /* font-size: 12px; */
    font-weight: 500;
    line-height: 20px;
  }

  .zoominout {
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 600;
    line-height: 2.1;
  }

  .scoreboard-2 .score-header .detail-score .score-details .runrate-center {
    bottom: -3px;
  }

  .scoreboard-2 .score-header .more-tab {
    /* display: none; */
  }

  .scoreboard-2 .score-header .detail-score .score-details .runs-need {
    bottom: 1px;
  }
}

@media (min-width: 576px) {
  .scoreboard-2 .score-header .detail-score .score-details .arrow {
    /* display: none; */
  }

  .more-tab-mobile {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  .umpire-image1 {
    animation: slideFromRight1 1s forwards;
  }

  .foreground-text1 {
    font-size: 25px;
  }

  .ScorePridiction {
    position: absolute;
    top: 58px;
  }

  .ScorePridictionSmall {
    width: 100%;
    position: absolute;
    top: 16px;
  }

  .scoreboard-2 {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 8px;
    padding-top: 7px;
  }

  .scoreboard-2 .score-header .center-content {
    width: 120px;
    height: 50px;
  }

  .scoreboard-2 .score-header .top-header {
    min-height: 50px;
  }

  .scoreboard-2 .score-header .top-header-1 {
    min-height: 50px;
  }

  .scoreboard-2 .score-header .detail-score .score-details {
    min-height: 105px;
  }

  .scoreboard-2 .score-header .detail-score .score-details table tr td {
    padding: 0 2px;
    /* font-size: 12px !important; */
    /* line-height: 20px !important; */
  }

  .scoreboard-2 .score-header .left-team .left-score .team-score,
  .scoreboard-2 .score-header .right-team .right-score .team-score {
    font-size: 24px;
  }

  .scoreboard-2 .score-header .left-team .team-name .team-title,
  .scoreboard-2 .score-header .right-team .team-name .team-title,
  .scoreboard-2 .score-header .separator .team-name .team-title {
    max-width: 100px;
  }

  .scoreboard-2 .score-header .left-score .overs,
  .scoreboard-2 .score-header .right-score .overs {
    font-size: 10px;
  }

  .scoreboard-2 .score-header .detail-score .score-details .details-center {
    width: 200px;
  }

  .scoreboard-2 .score-header .detail-score .small-score-details .more-tab .more-arrow {
    width: 110px;
  }

  .scoreboard-2 .score-header .current-over .balls .ball,
  .scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over .balls .ball {
    min-width: 18px;
    height: 18px;
    margin: 0 2px;
  }

  .scoreboard-2 .score-header .current-over,
  .scoreboard-2 .score-header .detail-score .score-details .details-center .over-details .last-over {
    margin: 2px 0;
  }

  .scoreboard-2 .score-header .detail-score .run-rates .run-rate {
    font-size: 10px;
  }

  .scoreboard-2 .score-header .detail-score .score-details .runrate-center .add-icon {
    right: 8px;
    bottom: 1px;
  }

  .scoreboard-2 .score-header .detail-score .score-details .runrate-center .add-icon a img {
    width: 25px;
    height: 25px;
  }

  .scoreboard-2 .mobile-score-details {
    display: none;
  }

  .scoreboard-2 .score-header .detail-score .score-details .runs-need {
    bottom: 3px;
  }

  .scoreboard-2 .score-header .detail-score .score-details .runrate-center {
    bottom: -4px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .ScorePridiction {
    position: absolute;
    top: 58px;
  }

  .foreground-text1 {
    font-size: 30px;
  }

  .scoreboard-2 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .scoreboard-2 .score-header .more-tab .down-arrow {
    top: -19px;
  }

  .scoreboard-2 .score-header .separator {
    width: 140px;
  }

  .scoreboard-2 .score-header .center-content {
    width: 140px;
  }

  .scoreboard-2 .score-header .left-team .team-name,
  .scoreboard-2 .score-header .right-team .team-name {
    font-size: 20px;
  }

  .scoreboard-2 .score-header .detail-score .score-details {
    padding: 5px;
  }

  .scoreboard-2 .score-header .detail-score .left-score .team-score,
  .scoreboard-2 .score-header .detail-score .right-score .team-score {
    font-size: 17px;
  }

  .scoreboard-2 .score-header .left-team .team-name .team-title,
  .scoreboard-2 .score-header .right-team .team-name .team-title,
  .scoreboard-2 .score-header .separator .team-name .team-title {
    max-width: 150px;
  }

  .scoreboard-2 .score-header .detail-score .score-details .runrate-center .add-icon {
    bottom: 3px;
  }

  .scoreboard-2 .score-header .detail-score .score-details table tr td {
    padding: 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    /*
    max-width: 60px;
    white-space: nowrap;
    */
  }

  .scoreboard-2 .score-header .detail-score .score-details .runs-need .title {
    bottom: 2px;
  }
}

@media (min-width: 767px) {
  .mobile-score-details {
    display: none;
  }

  .ScorePridiction {
    position: absolute;
    top: 58px;
  }

  .ScorePridictionSmall {
    width: 100%;
    position: absolute;
    top: 16px;
  }
}

.seprate {
  color: #fff;
  line-height: 23px;
  padding-left: 2px;
  padding-right: 3px;
}

.runs-need {
  /* color: #fff; */
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 0 5px;
  border-radius: 0;
  font-size: 8px;
  font-weight: 400;
  line-height: 20px;
}

.status-text {
  color: #fff;
}

.zoominout-text {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  -webkit-animation: txt 3s ease-out infinite;
  animation: txt 3s ease-out infinite;
}

.setplusBtnMargin {}

.mrn69 {
  margin-right: -69px;
}

.mrp69 {
  margin-right: 69px;
}

.bg-black {
  background-color: #000;
}

.message-container {
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.trial-message {
  align-items: center;
  background-color: #ffcccc;
  color: #800000;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

/* .team1Side {
  width: 28px !important;
  height: 28px;
  position: absolute;
  top: 12px;
  bottom: 0;
  left: 11px;
  right: auto;
}

.team2Side {
  width: 28px !important;
  height: 28px;
  position: absolute;
  top: 12px;
  bottom: 0;
  left: 11px;
  right: auto;
} */

@keyframes drawNumber1 {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes slideFromRight1 {
  0% {
    right: -100px;
  }

  100% {
    right: 23%;
  }
}

@media only screen and (max-width: 767px) {

  @keyframes slideFromRight1 {
    0% {
      right: -100px;
    }

    100% {
      right: 20%;
    }
  }
}

@media only screen and (max-width: 575px) {
  @keyframes slideFromRight1 {
    0% {
      right: -100px;
    }

    100% {
      right: 23%;
    }
  }
}

.w-80 {
  width: 80% !important;
}

.w-20 {
  width: 20% !important;
}

.w-60 {
  width: 60% !important;
}

.w-100 {
  min-width: 100% !important;
}

.over-container-2 {
  width: 100% !important;
  display: block;
}

.desktop-run-need {
  min-width: 100%;
  color: white;
  font: inherit;
  text-align: center;
}

.mobile-run-need {
  min-width: 100%;
  color: white;
  font: inherit;
  text-align: center;
  font-size: x-small;
}

.desktop-overs {
  flex-flow: column;
}

.more-tab-ext {
  height: 0 !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

::-webkit-scrollbar {
  display: none;
}

.current-over-ext {
  width: 100% !important;
  overflow: scroll;
}

.over-container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  min-width: 100%;
}

.over-label {
  line-height: 65% !important;
  text-decoration: underline;
  font-size: small;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.over-ball-style {
  min-width: 45px !important;
}

@media screen and (max-width: 768px) {
  .progress-bars-1 {
    .progress-title {
      .progress-amount {
        font-size: 8px;
      }
    }
    .progress {
      width: 96%;
    }
    .progress-content {
      width: 94%;
    }
  }

  .new-ball {
    min-width: 20px !important;
    margin: 5px 2px !important;
  }

  .over-ball-style {
    margin: 2px 3px 0 8px !important;
    height: 25px !important;
    min-width: 40px !important;
    padding: 2px !important;
    border-radius: 7px !important;
  }
}
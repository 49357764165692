.no-rate {
    background-color: #850912;
    color: #fff;
    width: 35px;
}

.rate-font {
    font-size: 12px;
    font-weight: 600;
}

.point-font {
    font-size: 8px;
}

.yes-rate {
    background-color: #0c5d78;
    color: #fff;
    width: 35px;
}

.sub-category {
  background: linear-gradient(88deg, #18165f, #00ffecc7);
  color: #fff;
}

.market-suspended-container {
  position: relative;
}

.market-suspended-container .no-rate-suspend {
  background-color: #850912;
  color: #fff;
  width: 35px;
  height: 40px;
  opacity: 0.6;
}

.market-suspended-container .yes-rate-suspend {
  background-color: #0c5d78;
  color: #fff;
  width: 35px;
  height: 40px;
  opacity: 0.6;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}

.overlay:after {
  background: none;
}

.suspended-text {
  font-size: 10px;
  color: #fff;
  font-weight: 500;
}

.table.market-table td {
    font-size: 11px;
}

.table td,
.table th {
    padding: 2px;
    text-align: center;
}

.table.widget-table th:first-of-type {
    padding-left: 20px;
    text-align: left;
}
.table.widget-table td:first-of-type {
    padding-left: 20px;
    text-align: left;
}

.sidebar-category .table.widget-table th:first-of-type {
  padding-left: 20px;
  text-align: left;
  width: 55%;
}

.sidebar-category .table.widget-table td:first-of-type {
  padding-left: 20px;
  text-align: left;
  width: 55%
}

.settled-table td,
.settled-table th {
    padding: 0.75rem;
    text-align: center;
} 

.settled-table.widget-table th:first-of-type {
    padding-left: 20px;
    text-align: left;
}

.table.widget-table td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.font-black {
    color: black !important;
}

.table-scrollbar {
    max-height: 300px; 
    overflow-y: auto;
}
.table-responsive {
    scrollbar-width: thin;
    border-radius: 0 0 0 0;
}

.progress-bars-2 {
    line-height: 21px;
    .progress-title {
      .progress-amount {
        font-size: 12px;
        font-weight: 600;
      }
    }
    .progress-content {
      width: 96%;
    }
    .progress {
      width: 98%;
      height: 6px;
      background: transparent;
      border-radius: 5px;
      border: 1px solid #777777;
      /* margin-bottom: 5px; */
      display: flex;
      animation: progressBar 1s ease-in-out;
      animation-fill-mode:both;
  
      .determinate {
        border-radius: 27.22px;
      }
  
      /* .progress-left {
        display: flex;
        width: 60%;
        background: var(--determinate-bg);
      }
  
      .progress-right {
        display: flex;
        width: 40%; 
        background-color: var(--progress-bg);
      } */
    }
}
@media (max-width: 575px) {
    .progress-bars-2 {
      .progress {
        width: 94% !important;
      }
      .progress-content {
        width: 92% !important;
      }
    }
}
@media screen and (max-width: 768px) {
    .progress-bars-2 {
      .progress-title {
        .progress-amount {
          font-size: 10px;
        }
      }
      .progress {
        width: 96%;
      }
      .progress-content {
        width: 94%;
      }
    }
}
@keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}
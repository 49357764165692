@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-ExtraLight.woff2) format("woff2"), url(/public/fonts/Montserrat-ExtraLight.woff) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-Medium.woff2) format("woff2"), url(/public/fonts/Montserrat-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-Italic.woff2) format("woff2"), url(/public/fonts/Montserrat-Italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-LightItalic.woff2) format("woff2"), url(/public/fonts/Montserrat-LightItalic.woff) format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-Light.woff2) format("woff2"), url(/public/fonts/Montserrat-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-ExtraLightItalic.woff2) format("woff2"), url(/public/fonts/Montserrat-ExtraLightItalic.woff) format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-SemiBoldItalic.woff2) format("woff2"), url(/public/fonts/Montserrat-SemiBoldItalic.woff) format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-Regular.woff2) format("woff2"), url(/public/fonts/Montserrat-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-SemiBold.woff2) format("woff2"), url(/public/fonts/Montserrat-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-MediumItalic.woff2) format("woff2"), url(/public/fonts/Montserrat-MediumItalic.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-Thin.woff2) format("woff2"), url(/public/fonts/Montserrat-Thin.woff) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-Black.woff2) format("woff2"), url(/public/fonts/Montserrat-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-ThinItalic.woff2) format("woff2"), url(/public/fonts/Montserrat-ThinItalic.woff) format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-ExtraBold.woff2) format("woff2"), url(/public/fonts/Montserrat-ExtraBold.woff) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-ExtraBoldItalic.woff2) format("woff2"), url(/public/fonts/Montserrat-ExtraBoldItalic.woff) format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-BoldItalic.woff2) format("woff2"), url(/public/fonts/Montserrat-BoldItalic.woff) format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-BlackItalic.woff2) format("woff2"), url(/public/fonts/Montserrat-BlackItalic.woff) format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap
}

@font-face {
  font-family: montserrat;
  src: url(/public/fonts/Montserrat-Bold.woff2) format("woff2"), url(/public/fonts/Montserrat-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}


body {
  background-color: transparent
}

.display-none {
  display: none !important
}

.display-block {
  display: block !important
}

.pr-10 {
  padding-right: 10px !important
}

.pl-10 {
  padding-left: 10px !important
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
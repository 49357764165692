.card {
  position: relative;
}

.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  text-align: center;
  opacity: 0.8;
}

.login-container .btn {
  margin: 0 auto;
  font-size: small;
}
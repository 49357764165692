.borderRadius{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.teamFlag{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color:#eff0f5;
    margin-right: 10px;
  }
.font-sm-cm{
    font-size: 13px;
}
.font-sm-cm-1{
    font-size: 14.5px;
}
.height-0{
    height: 0px;
}
.width-full{
    width: 100%;
}

table td {
    font-size: 12px;
}
.imageRoot{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.imageRootPlayer{
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.player-left {
  margin-left: 25px;
}
.player-right {
  margin-right: 30px;
}
.imageRootToOvers{
    position: relative;
    overflow: hidden;
    margin-left: 10px;
    border: solid #e2e3e4 1px;
    background: #eff0f5;
}

.imageRootToScoreCard{
    position: relative;
    overflow: hidden;
    border: solid #e2e3e4 1px;
    background: #eff0f5;
}
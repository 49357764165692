.banner {
  /* .swiper-button-prev, .swiper-button-next {
    color: #000;
  } */
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    display: none !important;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    background: black;
    opacity: 0.5;
    color: white;
    padding: 6px;

    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
  }
  --swiper-navigation-size: 12px;
}

.header-cards {
  position: relative !important;
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    display: none !important;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: white;
    font-size: var(--swiper-navigation-size);
  }
  --swiper-navigation-size: 15px;
}

.news-card-radius {
  border-radius: 0;
}

.banner-card {
  /* height: 220px; */
  width: 100%;
}

.news-card {
  /* height: 220px; */
  width: 100%;
}

.news-display {
  display: block;
  color: #fff !important;
  background: #ff0000 !important;
  font-size: 12px !important;
  font-weight: 600;
  width: fit-content;
  padding: 0px 2px;
}
.bg-pattern {
  background-image: url("../../public/bg.jpg");
  background-size: cover;
  background-position: center;
}

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: black;
}

.google-button {
  width: 100% !important;
  border-radius: 5px !important;
  display: ruby !important; 
  border: 1px solid black !important;
  color: black !important;
}

.login-button{
  height: 35px;
}

.login-text {
  color: black;
}

.privacy-policy {
 font-size: 10px !important;
}

.login-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, .54);
  width: 90px;
  margin: auto;
}

.kep-login-facebook {
  font-weight: 500 !important;
  padding: 10px 10px 10px 10px !important;
  font-size: 12px !important;
  background-color: #fff !important;
  color: black !important;
  display: ruby !important;
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid black !important;
}

label {
  font-weight: 600;
  color: #505d69;
}

.form-control {
  color: #505d69;
  height: 50px;
  width: 100%;
  border: 1px solid #d0d3d4;
  border-radius: 5px;
  padding: 0 0 0 10px;
  font-size: 13px;
}

.otp-css {
  width: 40px !important;
  height: 40px !important;
}

.login-mt {
  margin-top: 3rem;
}

.cursor {
  cursor: pointer;
}
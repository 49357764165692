.notification-count {
  position: absolute;
  top: -15px;
  right: -21px;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 1px 8px;
  font-size: 10px;
  font-weight: 700;
}

.logout-link {
  color: #fff !important;
}

.login-link {
  color: #fff !important;
}

.dropdown-profile {
  color: #fff !important;
}

.dropdown-menu {
  max-height: 400px;
  width: 200px;
  overflow-y: auto;
}

.dropdown-item {
  display: block;
  font-size: 12px;
  padding: 10px;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #c6c6c6e1;
}

.notification-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 50%;
  margin-right: 5px;
}

.profile-details {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-details h3 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #1e1d1d;
}

.profile-details p {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.profile-details p strong {
  color: #222;
}

.profile-details .btn {
  display: inline-block;
  padding: 5px 10px;
  margin: 10px 0;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.profile-details .btn:hover {
  opacity: 0.9;
}

.home-logo {
  width: 60px;
}
.header-skeleton {
  border-radius: 10px;
}
.match-content-skeleton {
  height: auto;
  width: 100%;
}
.news-match-skeleton {
  height: auto;
  width: 100%;
}
.banner-skeleton {
  height: auto;
  width: 100%;
}
.score-header-skeleton {
  height: auto;
  width: 100%;
}
.score-card-inner-skeleton {
  padding: 7px;
}
body {
  --header-text-color: #fff;
  --white-text: #fff;
  --black-text: #000;
  --all-event-bg: #fff;
  --scoreboard-span-color: #f8ea25;
  --scorboard-width40: #c80a00;
  --background-color: #fff;
  --scorboard-width60: #c3e329;
  --body-bg-color: #f4f4f5;
  --black-border: #000;
  --download-app-box-shadow: 4px 4px 0px 0px #000000;
  --icon-holder: #f0f0f0;
  --bet-background: linear-gradient(to right, #c80a00, #142fa5);
  --table-title: #091c72;
  --balls-bg: #abaad1;
  --score-team-title: #fff;
  --run-bg: #ff5959;
  --run-four-bg: #5498ff;
  --run-six-bg: #3dff50;
  --progress-bg: #ff0043;
  --determinate-bg: #56c300;
}

/* * {
  font-family: "Roboto", sans-serif;
} */

/* html,
body {
  font-weight: 400;
  color: var(--primary-text-color);
  font-family: "Roboto", sans-serif;
} */

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.m-auto {
  margin: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.v-m {
  vertical-align: middle;
}

.w-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.h-100 {
  height: 100%;
  max-height: 100% !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.justify-left {
  justify-content: left !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-right {
  justify-content: right !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.justify-space-evenly {
  justify-content: space-evenly !important;
}

.align-items-center {
  align-items: center !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.trans-bg {
  background-color: transparent;
}

.position-relative {
  position: relative !important;
}

.bg-transparent {
  background: transparent;
}

.hidden {
  visibility: hidden;
}

.team-img-1 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  box-shadow: inset 1px 2px 16px #7a7a7a;
  background: #fff;
  line-height: 2;
  display: inline-block;
  position: relative;
  top: -8px;
  font-size: x-small;
}

.team-img-1 img {
  border-radius: 50%;
}

.player1 img {
  width: 22px;
  padding: 1px;
}

.player1-img {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 3px 3px 3px;
  width: 35px;
  height: 35px;
  padding: 1px;
  border-radius: 3px;
  object-fit: cover;
  object-position: center bottom;
  overflow: hidden;
}

.display-status-blink {
  animation: display-status-zoom 3s infinite;
}

.scoreboard2 {
  font-family: "Roboto", sans-serif;
  color: #fff;
  /* margin-bottom: 5px; */
  /* margin-top: 5px; */
  display: flow-root;
  width: 100%;
  margin-bottom: 0;
   
  .board-css {
    padding-left: 0px;
    padding-right: 0px;
  }

  .score-card2 table {
    width: 100%;
    border-collapse: collapse;
  }

  .carousel-indicators {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    list-style: none;
  }

  .carousel-indicators li {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
    margin: 0 5px;
    cursor: pointer;
  }

  .carousel-indicators .active {
    background-color: var(--white-text);
  }

  .swiper-button-prev, .swiper-button-next {
    color: #fff;
    animation: blink 0.5s infinite alternate;
  }
  .swiper-button-next {
    padding-left: 15px
  }
  .swiper-button-prev {
    padding-right: 15px;
  }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    display: none;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
  }
  --swiper-navigation-size: 17px;
  
  .selectbet-header {
    height: 100%;
    /* min-height: 160px; */
    position: relative;
    /* border-radius: 27px; */
    padding: 20px 20px 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    .left-star {
      position: absolute;
      top: 33%;
      left: 39%;

      img {
        width: 80%;
        max-width: 50px;
      }
    }

    .right-star {
      position: absolute;
      top: 33%;
      right: 39%;

      img {
        width: 80%;
        max-width: 50px;
      }
    }

    .run-needs-2 {
      color: var(--score-team-title);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      position: relative;
      top: 0px;
    }

    .ball-counter-2 {
      p {
        color: var(--score-team-title);
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 0;
        margin-top: 15px;
      }
    }

    .event-name {
      position: relative;
      line-height: 46px;

      .team-title {
        color: var(--all-event-bg);
        font-size: 17px;
        line-height: 30px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 0px;

        img {
          width: 23px;
          height: 23px;
          margin-right: 5px;
        }

        svg {
          width: 23px;
          height: 23px;
          margin-right: 5px;
        }
      }

      .center-content {
        width: 20%;

        .ball-counter {
          p {
            color: var(--score-team-title);
            text-align: center;
            font-size: 17px;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 0;
            margin-top: 0;
          }
        }

        .balls {
          display: flex;
          justify-content: center;

          ul {
            list-style: none;
            display: flex;
            margin: 5px 0;

            li {
              min-width: 24px;
              width: auto;
              height: 24px;
              border: 1px solid var(--score-team-title);
              border-radius: 20px;
              margin-left: 3px;
              font-size: 8px;
              justify-content: center;
              align-items: center;
              display: flex;
              color: var(--black-text);

              &.ball {
                background-color: var(--white-text);
                color: var(--black-text);
                text-align: center;
                font-size: 15px;
                font-weight: 700;
                line-height: 14px;
              }

              &.first-ball {
                background-color: var(--body-bg-color);
              }

              &.second-ball {
                background-color: var(--body-bg-color);
              }

              &.third-ball {
                background-color: var(--run-bg);
              }

              &.four-ball {
                background-color: var(--run-four-bg);
              }

              &.five-ball {
                background-color: var(--run-six-bg);
              }

              &.six-ball {
                background: transparent;
              }
            }
          }
        }
      }

      .width-40 {
        width: 40%;

        .left-team-title {
          .team-details {
            display: flex;
            line-height: 26px;

            .team-title-full {
              font-size: 45px;
              font-weight: 600;
              /* color: var(--score-team-title); */
              text-shadow: var(--score-header-text-shadow);
              display: inline-block;
              margin-left: 10px;
            }

            .team-run span {
              font-size: 45px;
              font-weight: 600;
              /* color: var(--score-team-title); */
              text-shadow: var(--score-header-text-shadow);
              display: inline-block;
              margin-left: 30px;
            }
          }

          .bestment {
            background: linear-gradient(90deg,
                rgba(255, 255, 255, 0) 0.63%,
                #fff 53.77%,
                rgba(255, 255, 255, 0) 100.63%);
            padding: 1px 5px !important;
            width: 100%;
            max-width: 260px;
            top: 2px;
            position: relative;
          }

          .progress {
            width: 36%;
            height: 5px;
            background-color: var(--progress-bg);
            border-radius: 5px;
            border: 0.6px solid var(--score-team-title);
            margin: 0;

            .determinate {
              border-radius: 27px;
              background: var(--determinate-bg);
            }
          }

          .team-score {
            p {
              font-size: 35px;
              font-weight: 400;
              line-height: 35px;
              /* color: var(--score-team-title); */
              margin: 5px 20px 0px 40px;
            }
          }
        }

        .right-team-title {
          align-items: center;
          text-align: center;
          float: right;

          .team-details {
            display: flex;
            justify-content: center;
            line-height: 26px;

            .team-title-full {
              padding-left: 8px;
              font-size: 45px;
              font-weight: 600;
              /* color: var(--score-team-title); */
              text-shadow: var(--score-header-text-shadow);
              display: inline-block;
            }

            .team-run span {
              font-size: 45px;
              font-weight: 600;
              /* color: var(--score-team-title); */
              text-shadow: var(--score-header-text-shadow);
              display: inline-block;
              margin-left: 30px;
            }
          }

          .progress {
            width: 100%;
            height: 5px;
            background-color: var(--progress-bg);
            border-radius: 5px;
            border: 0.6px solid var(--score-team-title);
            margin: 0;

            .determinate {
              border-radius: 27.22px;
              background: var(--determinate-bg);
            }
          }

          .bestment {
            background: linear-gradient(90deg,
                rgba(255, 255, 255, 0) 0.63%,
                #fff 53.77%,
                rgba(255, 255, 255, 0) 100.63%);
            padding: 1px 5px !important;
            width: 100%;
            max-width: 260px;
            top: 2px;
            position: relative;
          }

          .team-score {
            p {
              font-size: 35px;
              font-weight: 400;
              line-height: 35px;
              /* color: var(--score-team-title); */
              margin: 5px 20px 0;
            }
          }
        }

        .right-team {
          float: unset;
        }
      }

      .bet-text {
        /* padding: 8px; */
        justify-content: space-between;
        align-items: center;
      }

      .run-needs {
        color: var(--score-team-title);
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        line-height: 25px;
        position: relative;
        top: 0px;
      }

      .progress-bars {
        line-height: 20px;

        .progress-title {
          .progress-amount {
            color: var(--score-team-title);
            font-size: 10px;
            font-weight: 500;
          }
        }
        
        .progress-content {
          width: 96%;
        }

        .progress {
          width: 98%;
          height: 6px;
          background: transparent;
          border-radius: 5px;
          border: 0.5px solid var(--score-team-title);
          /* margin-bottom: 5px; */
          display: flex;

          .determinate {
            border-radius: 27.22px;
          }

          /* .progress-left {
            display: flex;
            width: 60%;
            background: var(--determinate-bg);
          } */

          /* .progress-right {
            display: flex;
            width: 40%;
            background-color: var(--progress-bg);
          } */
        }
      }
    }

    &.banner-2 {
      padding: 5px 30px 5px;

      .center-star {
        position: absolute;
        top: 34%;
        left: 48%;

        img {
          width: 80%;
        }
      }

      .event-name {
        .bet-text {
          /* padding: 8px; */
          align-items: flex-start;
        }

        .progress-bars {
          line-height: 20px;

          .progress-title {
            .progress-amount {
              color: var(--score-team-title);
              font-size: 10;
              font-weight: 500;
            }
          }

          .progress {
            width: 98%;
            height: 6px;
            background-color: var(--progress-bg);
            border-radius: 5px;
            border: 0.5px solid var(--score-team-title);
            /* margin-bottom: 5px; */

            .determinate {
              border-radius: 27.22px;
              background: var(--determinate-bg);
            }
          }
        }

        .score-card2 {
          /* color: var(--score-team-title); */

          table {
            tr {
              border-bottom: none;
              line-height: 35px;

              td {
                font-size: 15px;
                text-align: center;
                padding: 0px 5px;

                &.bestment {
                  background: linear-gradient(90deg,
                      rgba(255, 255, 255, 0) 0.63%,
                      #fff 53.77%,
                      rgba(255, 255, 255, 0) 100.63%);
                  padding: 1px 5px !important;
                }

                &:first-child {
                  text-align: left;
                  padding-left: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              th {
                font-size: 15px;
                padding: 0 10px;
                text-align: center;
                font-weight: 600;
                text-transform: uppercase;

                &:first-child {
                  text-align: left;
                  padding-left: 0;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }

  .banner-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}

.slick-dots {
  li {
    margin: 0 -2px;
  }
}

@media only screen and (max-width: 768px) {
  .playerName {
    max-width: 85px;
  }

  .scoreboard2 {
    .selectbet-header {
      .left-star {
        left: 32%;
        top: 54%;

        img {
          width: 40%;
        }
      }
      .ball-counter-2 {
        p {
          margin-top: 5px;
        }
      }
      .right-star {
        right: 25%;
        top: 54%;

        img {
          width: 40%;
        }
      }

      .event-name {
        .bet-text {
          padding: 6px;
          padding-left: 0px;
        }

        .team-title {
          font-size: 15px;
          top: 6px;
        }

        .width-40 {
          width: 30%;

          .left-team-title {
            .progress {
              width: 80%;
            }

            .team-details {
              .team-title-full {
                font-size: 25px;
                margin-left: 10px;
              }

              .team-run {
                span {
                  font-size: 25px;
                  margin-left: 10px;
                }
              }
            }

            .team-score {
              p {
                font-size: 18px;
                margin: 0px 20px 0px 42px;
              }
            }
          }

          .right-team-title {
            .team-details {
              .team-title-full {
                font-size: 25px;
              }

              .team-run {
                span {
                  font-size: 25px;
                  margin-left: 10px;
                }
              }
            }

            .team-score {
              p {
                font-size: 18px;
                margin: 0 20px;
              }
            }
          }
        }

        .center-content {
          width: 40%;

          .ball-counter {
            p {
              margin: 0;
              font-size: 15px;
              line-height: 32px;
            }
          }

          .balls {
            ul {
              li {
                min-width: 20px;
                height: 20px;
                font-size: 12px;
                line-height: 12px;
              }
            }
          }

          .team-title {
            font-size: 13px;
          }

          .run-needs {
            font-size: 13px;
          }
        }

        .run-needs {
          font-size: 15px;
          top: -6px;
        }
      }

      &.banner-2 {
        padding: 5px 20px 5px;

        .center-star {
          img {
            width: 50%;
          }
        }

        .event-name {
          .bet-text {
            padding: 6px;
          }

          .width-40 {
            width: 48%;
          }

          .score-card2 {
            table {
              tr {
                line-height: 15px;

                td {
                  font-size: 14px;
                  padding: 5px;

                  &.bestment {
                    top: 0;
                    background: linear-gradient(90deg,
                        rgba(255, 255, 255, 0) 20.63%,
                        #fff 40.77%,
                        rgba(255, 255, 255, 0) 80.63%);
                  }
                }

                th {
                  font-size: 14px;
                }
              }
            }
          }

          .progress-bars {
            line-height: 20px;

            /* .progress {
              width: 80%;
            } */

            .progress-title {
              .progress-amount {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .scoreboard2 {
    /* .carousel-indicators {
      bottom: -15px;
    } */
    .selectbet-header {
      .event-name {
        .progress-bars {
          line-height: 20px;
          padding: 0 5px;

          .progress-title {
            .progress-amount {
              font-size: 10px;
            }
          }

          /* .progress {
            width: 76%;
          } */
        }

        .run-needs {
          color: var(--score-team-title);
          text-align: center;
          font-size: 11px;
          font-weight: 500;
          line-height: 16px;
          top: 0px;
        }
      }

      &.banner-2 {
        padding: 5px;

        .event-name {
          .progress-bars {
            /* .progress {
              width: 75%;
            } */

            .progress-title {
              .progress-amount {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .scoreboard2 {
    .selectbet-header {
      .event-name {
        /* padding-bottom: 15px; */
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .scoreboard2 {
    .selectbet-header {
      padding: 5px !important;
      /* border-radius: 20px; */

      .left-star {
        left: 32%;
        top: 54%;

        img {
          width: 40%;
        }
      }

      .right-star {
        right: 25%;
        top: 54%;

        img {
          width: 40%;
        }
      }

      .ball-counter-2 {
        p {
          margin-top: 0px;
        }
      }

      .event-name {
        .width-40 {
          width: 30%;

          .left-team-title {
            .progress {
              width: 100%;
            }
            /* .bestment {
              width: 75%;
            } */
            .team-details {
              /* justify-content: center; */
              .team-title-full {
                font-size: 23px;
                /* margin-left: 0px; */
              }

              .team-run {
                span {
                  font-size: 23px;
                  margin-left: 10px;
                }
              }
            }

            .team-score {
              p {
                font-size: 17px;
                /* margin: 0px 15px 0px 15px; */
                line-height: 35px;
                /* text-align: center; */
              }
            }
          }

          .right-team-title {
            .team-details {
              .team-title-full {
                font-size: 23px;
              }

              .team-run {
                span {
                  font-size: 23px;
                  margin-left: 10px;
                }
              }
            }

            .team-score {
              p {
                font-size: 17px;
                margin: 0 20px;
                line-height: 35px;
              }
            }
          }
        }

        .center-content {
          width: 40%;

          .ball-counter {
            p {
              margin: 0;
              font-size: 15px;
              line-height: 32px;
            }
          }

          .balls {
            ul {
              margin: 5px 0;

              li {
                min-width: 14px;
                height: 14px;
                font-size: 12px !important;
                line-height: 12px !important;
              }
            }
          }

          .team-title {
            font-size: 12px;
          }

          .run-needs {
            font-size: 11px;
          }
        }

        .progress-bars {
          line-height: 20px;

          .progress-title {
            .progress-amount {
              font-size: 10px;
            }
          }

          /* .progress {
            width: 80%;
          } */
        }
      }

      &.banner-2 {
        padding: 9px 10px 6px !important;

        .center-star {
          img {
            width: 35% !important;
          }
        }

        .event-name {
          .width-40 {
            width: 100% !important;
          }

          .score-card2 {
            table {
              tr {
                td {
                  font-size: 13px;
                  padding: 1px 1px;
                  line-height: 15px;

                  &:first-child {
                    width: 40%;
                  }
                }

                th {
                  font-size: 13px;
                  /* padding: 4px;
                  line-height: 15px; */

                  &:first-child {
                    width: 40%;
                  }
                }
              }
            }
          }

          .progress-bars {
            .progress-title {
              .progress-amount {
                font-size: 10px;
              }
            }
          }
        }
      }

      &.banner-3 {
        padding: 30px 20px 30px !important;
      }
    }

    .event-name {
      .score-card2 {
        table {
          tr {

            th,
            td {
              width: 15%;

              &:first-child {
                text-align: left;
                width: 25%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .scoreboard2 {

    .selectbet-header {
      padding: 2px;
      /* border-radius: 15px; */

      .left-star {
        top: 67%;
        left: 31%;

        img {
          width: 30%;
        }
      }

      .right-star {
        top: 67%;
        right: 17%;

        img {
          width: 30%;
        }
      }

      .ball-counter-2 {
        p {
          margin-top: 0px;
        }
      }

      .event-name {
        .team-title {
          font-size: 11px;
          line-height: 25px;
          top: 4px;
        }

        .width-40 {
          width: 32% !important;
          margin: 0 auto;
          text-align: center;
          padding: 6px 0;

          .left-team-title {
            .progress {
              width: 90%;
              margin-bottom: 0;
              margin: 0 auto;
            }

            .team-details {
              display: flex;
              margin-bottom: 0px;
              align-items: center;
              /* justify-content: center; */
              line-height: 26px;

              .team-title-full {
                font-size: 23px;
                /* margin-left: 0; */
              }

              .team-run {
                span {
                  font-size: 23px;
                  margin-left: 8px;
                  line-height: 20px;
                }
              }
            }

            .team-score {
              p {
                font-size: 17px;
                margin: 4px 0px;
                line-height: 20px;
              }
            }

            .score-card2 {
              table {
                tr {
                  line-height: 8px;
                }

                th {
                  font-size: 13px !important;
                }

                td,
                th {
                  font-size: 13px;
                  width: 11%;
                  padding: 4px 1px !important;

                  &:first-child {
                    text-align: left;
                    width: 40%;
                  }
                }

                tr {
                  .bestment {
                    padding: 1px 5px !important;
                  }
                }
              }
            }
          }

          .right-team-title {
            float: unset !important;
            text-align: center;

            .progress {
              margin: 0 auto;
              width: 90%;
            }

            .team-details {
              display: flex;
              text-align: center;
              margin-bottom: 0;
              align-items: center;
              justify-content: center;
              line-height: 26px;

              .team-title-full {
                font-size: 23px;
                margin-left: 0;
              }

              .team-run {
                span {
                  font-size: 23px;
                  margin-left: 8px;
                  line-height: 20px;
                }
              }
            }

            .team-score {
              p {
                font-size: 17px;
                margin: 4px 0px;
                line-height: 20px;
              }
            }

            .score-card2 {
              table {
                tr {
                  line-height: 8px;
                }

                th {
                  font-size: 13px !important;
                }

                td,
                th {
                  font-size: 13px;
                  width: 11%;
                  padding: 4px 1px !important;

                  &:first-child {
                    text-align: left;
                    width: 40%;
                    padding: 4px 4px !important;
                  }
                }

                tr {
                  .bestment {
                    padding: 1px 5px !important;
                  }
                }
              }
            }
          }
        }

        .center-content {
          width: 32%;

          .team-title {
            font-size: 10px;
          }

          .ball-counter {
            p {
              margin-top: 0px;
              font-size: 12px;
              line-height: 37px;
            }
          }

          .balls {
            ul {
              padding: 0;
              margin-top: 0;
              margin-bottom: 8px;

              li {
                font-size: 9px !important;
                line-height: 6px !important;
              }
            }
          }
        }

        .center-content {
          .team-title {
            font-size: 11px;
            line-height: 15px;
            text-align: center;

            img {
              width: 18px;
              height: 18px;
            }
          }

          .balls {
            ul {
              li {
                min-width: 14px;
                height: 14px;
                font-size: 11px;
              }
            }
          }
        }
      }

      &.banner-2 {
        padding: 4px 10px 4px;

        .center-star {
          top: 48%;
          left: 46%;

          img {
            width: 45%;
          }
        }

        .event-name {
          .width-40 {
            padding: 0;
          }

          .bet-text {
            display: block !important;

            .width-40 {
              width: 100% !important;
            }

            .center-content {
              width: 40%;
            }
          }
        }

        /* .progress-bars {
          .progress {
            margin-bottom: 3px;
          }
        } */
      }

      &.banner-3 {
        padding: 32px 15px 33px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 359px) {
  .playerName {
    max-width: 75px;
  }
  .scoreboard2 {

    .selectbet-header {
      padding: 8px 5px 5px;
      /* border-radius: 15px; */

      .left-star {
        top: 67%;
        left: 28%;

        img {
          width: 30%;
        }
      }

      .right-star {
        top: 67%;
        right: 13%;

        img {
          width: 30%;
        }
      }

      .ball-counter-2 {
        p {
          margin-top: 0px;
        }
      }

      .event-name {
        .team-title {
          font-size: 12px;
          top: 12px;
        }

        .width-40 {
          width: 32% !important;
          margin: 0 auto;
          text-align: center;
          padding-top: 0px;

          .left-team-title {
            .progress {
              width: 90%;
              margin-bottom: 0;
              margin: 0 auto;
            }

            .team-details {
              display: flex;
              margin-bottom: 0px;
              align-items: center;
              justify-content: center;

              .team-title-full {
                font-size: 18px;
                /* margin-left: 0; */
              }

              .team-run {
                span {
                  font-size: 18px;
                  margin-left: 8px;
                  line-height: 20px;
                }
              }
            }

            .team-score {
              p {
                font-size: 15px;
                margin: 4px 0px;
                line-height: 20px;
              }
            }

            .score-card2 {
              table {
                tr {
                  line-height: 8px;
                }

                th {
                  font-size: 11px !important;
                }

                td,
                th {
                  padding: 2px 1px !important;
                  font-size: 12px;
                }

                tr {
                  .bestment {
                    padding: 1px 5px !important;
                  }
                }
              }
            }
          }

          .right-team-title {
            float: unset !important;
            text-align: center;

            .progress {
              margin: 0 auto;
              width: 90%;
            }

            .team-details {
              display: flex;
              text-align: center;
              margin-bottom: 0;
              align-items: center;
              justify-content: center;

              .team-title-full {
                font-size: 18px;
                margin-left: 0;
              }

              .team-run {
                span {
                  font-size: 18px;
                  margin-left: 8px;
                  line-height: 20px;
                }
              }
            }

            .team-score {
              p {
                font-size: 15px;
                margin: 4px 0px;
                line-height: 20px;
              }
            }

            .score-card2 {
              table {
                tr {
                  line-height: 8px;
                }

                th {
                  font-size: 10px !important;
                }

                td,
                th {
                  padding: 2px 1px !important;
                  font-size: 10px;
                }

                tr {
                  .bestment {
                    padding: 1px 5px !important;
                  }
                }
              }
            }
          }
        }

        .center-content {
          width: 32%;

          .team-title {
            font-size: 10px;
          }

          .ball-counter {
            p {
              margin-top: 0px;
              font-size: 12px;
              line-height: 37px;
            }
          }

          .balls {
            ul {
              padding: 0;
              margin-top: 0;
              margin-bottom: 8px;

              li {
                font-size: 9px !important;
                line-height: 6px !important;
              }
            }
          }
        }

        .run-needs {
          font-size: 12px;
          top: -5px;
        }

        .center-content {
          .team-title {
            font-size: 10px;
            line-height: 12px;
            text-align: center;

            img {
              width: 18px;
              height: 18px;
            }
          }

          .run-needs {
            font-size: 11px;
            line-height: 16px;
          }

          .balls {
            ul {
              li {
                min-width: 17px;
                height: 17px;
                font-size: 11px;
                margin-left: 2px;
              }
            }
          }
        }

        /* .progress-bars {
          .progress {
            width: 70%;
          }
        } */
      }

      &.banner-2 {
        padding: 5px;

        .center-star {
          top: 40%;
          left: 50%;

          img {
            width: 45%;
          }
        }

        .event-name {
          .width-40 {
            padding-top: 0px;
          }

          .bet-text {
            display: block !important;

            .width-40 {
              width: 100% !important;
            }

            .center-content {
              width: 40%;
            }
          }

          .progress-bars {
            line-height: 17px;

            /* .progress {
              margin-bottom: 3px;
              width: 70%;
            } */
          }
        }

        .progress-bars {
          .progress-title {
            .progress-amount {
              font-size: 10px !important;
            }
          }

          /* .progress {
            margin-bottom: 3px;
            width: 70%;
          } */
        }
      }

      &.banner-3 {
        padding: 32px 15px 33px !important;
      }
    }
  }
}

@media (min-width: 575px) and (max-width: 699px) {
  .scoreboard2 {
    .selectbet-header {
      padding: 10px 10px;

      .left-star {
        left: 32%;
        top: 54%;

        img {
          width: 40%;
        }
      }

      .right-star {
        right: 25%;
        top: 54%;

        img {
          width: 40%;
        }
      }

      .event-name {
        .width-40 {
          width: 30%;

          .left-team-title {
            .progress {
              width: 100%;
            }

            .team-details {
              /* justify-content: center; */

              .team-title-full {
                font-size: 23px;
                /* margin-left: 0px; */
              }

              .team-run {
                span {
                  font-size: 23px;
                  margin-left: 10px;
                }
              }
            }

            .team-score {
              p {
                font-size: 17px;
                margin: 0px 20px 0px 31px;
                line-height: 35px;
                /* text-align: center; */
              }
            }
          }

          .right-team-title {
            margin-top: 5px;

            .team-details {
              .team-title-full {
                font-size: 23px;
              }

              .team-run {
                span {
                  font-size: 23px;
                  margin-left: 10px;
                }
              }
            }

            .team-score {
              p {
                font-size: 17px;
                margin: 0 20px;
                line-height: 35px;
              }
            }
          }
        }

        .center-content {
          width: 40%;

          .ball-counter {
            p {
              margin: 0;
              font-size: 15px;
              line-height: 32px;
            }
          }

          .balls {
            ul {
              margin: 5px 0;

              li {
                min-width: 14px;
                height: 14px;
                font-size: 12px !important;
                line-height: 12px !important;
              }
            }
          }

          .team-title {
            font-size: 13px;
          }

          .run-needs {
            font-size: 13px;
          }
        }

        /* .progress-bars {
          .progress {
            width: 81%;
          }
        } */
      }

      &.banner-2 {
        padding: 5px 20px 5px;

        .center-star {
          img {
            width: 50%;
          }
        }

        .event-name {
          .width-40 {
            width: 48%;
          }

          .score-card2 {
            table {
              tr {
                td {
                  font-size: 13px;
                  padding: 4px;
                }

                th {
                  font-size: 13px;
                  padding: 1px;
                  line-height: 1.1;
                }
              }
            }
          }

          .progress-bars {
            /* margin-top: 5px; */

            .progress-title {
              .progress-amount {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .playerName {
    max-width: 85px;
  }
  .scoreboard2 {
    .selectbet-header {
      padding: 5px 20px 0px;
      /* padding-left: 0px; */

      /* .event-name {
        .progress-bars {
          .progress {
            width: 83%;
          }
        }
      } */

      &.banner-2 {
        .event-name {
          .score-card2 {
            table {
              tr {
                td {
                  font-size: 14px;
                  padding: 3px;
                  line-height: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .scoreboard2 {
    .selectbet-header {
      padding: 10px 20px 5px;

      .left-star {
        left: 32%;
        top: 54%;

        img {
          width: 40%;
        }
      }

      .right-star {
        right: 25%;
        top: 54%;

        img {
          width: 40%;
        }
      }

      .event-name {
        /* padding-bottom: 10px; */

        .team-title {
          font-size: 16px;
          top: 10px;
        }

        .width-40 {
          width: 40%;

          .left-team-title {
            .progress {
              width: 55%;
            }

            .team-details {
              .team-title-full {
                font-size: 30px;
                margin-left: 10px;
              }

              .team-run {
                span {
                  font-size: 30px;
                  margin-left: 10px;
                }
              }
            }

            .team-score {
              p {
                font-size: 20px;
                margin: 0px 20px 0px 42px;
              }
            }
          }

          .right-team-title {
            .team-details {
              .team-title-full {
                font-size: 30px;
              }

              .team-run {
                span {
                  font-size: 30px;
                  margin-left: 10px;
                }
              }
            }

            .team-score {
              p {
                font-size: 20px;
                margin: 0 20px;
              }
            }
          }
        }

        .center-content {
          width: 32%;

          .ball-counter {
            p {
              margin: 0;
            }
          }

          .balls {
            ul {
              li {
                min-width: 20px;
                height: 20px;
              }
            }
          }

          .team-title {
            font-size: 13px;
          }

          .run-needs {
            font-size: 13px;
          }
        }

        .run-needs {
          font-size: 16px;
          top: -4px;
        }

        .progress-bars {
          line-height: 25px;

          /* .progress {
            width: 86%;
          } */
        }
      }

      &.banner-2 {
        padding: 5px 20px 5px;

        .center-star {
          img {
            width: 50%;
          }
        }

        .event-name {
          .width-40 {
            width: 45%;
          }

          .score-card2 {
            table {
              tr {
                line-height: 25px;

                td {
                  font-size: 13px;
                }

                th {
                  font-size: 13px;
                }
              }
            }
          }

          .progress-bars {
            /* .progress {
              width: 85%;
            } */

            .progress-title {
              .progress-amount {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .scoreboard2 {
    .scoreboard-bottom {
      .detail-score {
        .score-details {
          .tabs {
            width: auto;
            float: left;
            padding: 0px;
            margin: 0px;
            height: 20px;

            .tab {
              margin: 0px 5px;
              line-height: normal;

              a {
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                color: var(--black-text);
                text-align: left;
                padding: 0px;

                &.active {
                  color: var(--table-title) !important;
                  background-color: transparent;
                }
              }
            }

            .indicator {
              background-color: var(--table-title) !important;
              height: 1px;
            }
          }
        }
      }
    }

    .selectbet-header {
      .bet-teamname {
        .team-names {
          display: none;
        }

        .teams-name {
          .team-title {
            .right-vs {
              width: 270px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .scoreboard2 {
    .selectbet-header {
      .event-name {
        .bet-text {
          padding-left: 0px;
          flex-wrap: wrap;
          padding: 0;
        }
      }
      &.banner-2 {
        .event-name {
          .bet-text {
            padding-left: 6px !important;
            padding-right: 0px;
            padding: 0;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1700px) {
  .scoreboard2 {
    .selectbet-header {
      &.banner-2 {
        .event-name {
          .progress-bars {
            .progress-title {
              .progress-amount {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .scoreboard2 {
    .selectbet-header {
      padding: 12px 20px 10px;

      .left-star {
        left: 36%;
        top: 54%;

        img {
          width: 50%;
        }
      }

      .right-star {
        right: 32%;
        top: 54%;

        img {
          width: 50%;
        }
      }

      .event-name {
        .team-title {
          top: 12px;
        }

        .width-40 {
          width: 32%;

          .left-team-title {
            .progress {
              width: 85%;
            }

            .team-details {
              .team-title-full {
                font-size: 40px;
              }

              .team-run {
                span {
                  font-size: 40px;
                }
              }
            }

            .team-score {
              p {
                font-size: 30px;
                margin: 0px 20px 0px 42px;
              }
            }
          }

          .right-team-title {
            .team-details {
              .team-title-full {
                font-size: 40px;
              }

              .team-run {
                span {
                  font-size: 40px;
                }
              }
            }

            .team-score {
              p {
                font-size: 30px;
                margin: 0 20px;
              }
            }
          }
        }

        .center-content {
          width: 32%;

          .team-title {
            font-size: 18px;
          }

          .run-needs {
            font-size: 17px;
          }
        }

        .run-needs {
          font-size: 16px;
          top: -3px;
        }

        /* .progress-bars {
          .progress {
            width: 90%;
          }
        } */
      }

      &.banner-2 {
        padding: 5px 15px 5px;

        .center-star {
          img {
            width: 50%;
          }
        }

        .event-name {
          .width-40 {
            width: 40%;
          }

          .score-card2 {
            table {
              tr {
                line-height: 25px;

                td {
                  font-size: 17px;
                }

                th {
                  font-size: 17px;
                }
              }
            }
          }

          .progress-bars {
            /* margin-top: 5px; */

            /* .progress {
              width: 85%;
            } */

            .progress-title {
              .progress-amount {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .scoreboard2 {
    .selectbet-header {
      .left-star {
        left: 36%;
        top: 52%;

        img {
          width: 70%;
        }
      }

      .right-star {
        right: 33%;
        top: 52%;

        img {
          width: 70%;
        }
      }

      .event-name {
        .team-title {
          top: 11px;
        }

        .width-40 {
          width: 32%;

          .left-team-title {
            .progress {
              width: 70%;
            }

            .team-details {
              .team-title-full {
                font-size: 40px;
              }

              .team-run {
                span {
                  font-size: 40px;
                }
              }
            }

            .team-score {
              p {
                font-size: 30px;
                margin: 10px 20px 0px 42px;
              }
            }
          }

          .right-team-title {
            .team-details {
              .team-title-full {
                font-size: 40px;
              }

              .team-run {
                span {
                  font-size: 40px;
                }
              }
            }

            .team-score {
              p {
                font-size: 30px;
                margin: 10px 20px 0px 42px;
              }
            }
          }
        }

        .center-content {
          width: 32%;

          .team-title {
            font-size: 18px;
          }

          .run-needs {
            font-size: 17px;
          }
        }

        .run-needs {
          top: -2px;
        }

        /* .progress-bars {
          .progress {
            width: 92%;
          }
        } */
      }

      &.banner-2 {
        padding: 11px 20px 12px;

        .center-star {
          img {
            width: 50%;
          }
        }

        .event-name {
          .score-card2 {
            table {
              tr {
                td {
                  font-size: 16px;
                  line-height: 33px;
                }

                th {
                  font-size: 19px;
                  line-height: 33px;
                }
              }
            }
          }

          .progress-bars {
            /* line-height: 30px; */

            /* .progress {
              width: 87%;
            } */

            .progress-title {
              .progress-amount {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1401px) and (max-width: 1600px) {
  .scoreboard2 {
    .selectbet-header {
      .left-star {
        img {
          width: 60%;
        }
      }

      .right-star {
        right: 37%;

        img {
          width: 60%;
        }
      }

      .event-name {
        .width-40 {
          .left-team-title {
            .team-details {
              .team-title-full {
                font-size: 45px;
              }

              .team-run {
                span {
                  font-size: 45px;
                }
              }
            }

            .team-score {
              p {
                font-size: 35px;
                margin: 10px 20px 0px 55px;
              }
            }
          }

          .right-team-title {
            .team-score {
              p {
                font-size: 35px;
                margin: 10px 20px 0px 55px;
              }
            }
          }
        }

        .center-content {
          .team-title {
            font-size: 18px;
          }

          .run-needs {
            font-size: 18px;
          }

          .ball-counter {
            p {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1601px) and (max-width: 1700px) {
  .scoreboard2 {
    .selectbet-header {
      .event-name {
        .center-content {
          .run-needs {
            font-size: 21px;
          }
        }
      }
    }
  }
}

.dynamic-score {
  position: relative;
  width: 100%;
  overflow: hidden
}

.foreground-text {
  font-size: 60px;
  font-family: monospace;
  font-weight: bolder;
  position: absolute;
  top: 32px;
  left: 0;
  /* width: 100%; */
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  animation: drawNumber 1s forwards;
  overflow: hidden;
  width: 0;
}

.umpire-image {
  position: absolute;
  top: 22%;
  right: -100px;
  height: 150px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 150px;
  animation: slideFromRight 1s forwards;
}

.backgound-banner {
  background-color: transparent;
}

@keyframes blink {
  from {
      opacity: 1;
  }
  to {
      opacity: 0.5;
  }
}

@keyframes display-status-zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes drawNumber {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes slideFromRight {
  0% {
    right: -100px;
  }
  100% {
    right: 23%;
  }
}

@media only screen and (max-width: 768px) {

  @keyframes slideFromRight {
    0% {
      right: -100px;
    }

    100% {
      right: 9%;
    }
  }
}